import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ListingDetail from './pages/ListingDetail';
import AdminDashboard from './admin/pages/AdminDashboard';
import ListingsPage from './pages/ListingsPage'; // Import ListingsPage
import LoginModal from './components/LoginModal/LoginModal'; // Import the reusable login modal
import './styles/App.css';
import BrokerDashboard from './broker/pages/BrokerDashboard'; // Add this import

// Function to get a cookie by name
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const App = () => {
    const [supertype, setSupertype] = useState('');
    const [type, setType] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

    const resetListings = () => {
        // Implement reset listings logic if needed
    };

    // Check for the token in cookies when the app loads
    useEffect(() => {
        const token = getCookie('authToken');
        if (token) {
            setIsLoggedIn(true); // If token is found, log the user in
        }
    }, []);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true); // Mark the user as logged in
    };

    return (
        <Router>
            <div className="App">
                {!isLoggedIn && <LoginModal onLoginSuccess={handleLoginSuccess} />} {/* Show modal until login */}
                
                {isLoggedIn && ( // Only allow access if the user is logged in
                    <Routes>
                        <Route 
                            path="/brokers" 
                            element={<BrokerDashboard />} 
                        />
                        <Route 
                            path="/" 
                            element={
                                <HomePage 
                                    setSupertype={setSupertype} 
                                    setType={setType} 
                                    resetListings={resetListings} 
                                />
                            } 
                        />
                        <Route 
                            path="/listings/:certNumber"
                            element={
                                <ListingDetail
                                    setSupertype={setSupertype}
                                    setType={setType}
                                    resetListings={resetListings}
                                />
                            }
                        />
                        <Route
                            path="/admin"
                            element={
                                <AdminDashboard
                                    setSupertype={setSupertype}
                                    setType={setType}
                                    resetListings={resetListings}
                                />
                            }
                        />
                        {/* New Routes for Each Supertype */}
                        <Route 
                            path="/pokemon" 
                            element={<ListingsPage supertype="pokemon" />} 
                        />
                        <Route 
                            path="/trainer" 
                            element={<ListingsPage supertype="trainer" />} 
                        />
                        <Route 
                            path="/energy" 
                            element={<ListingsPage supertype="energy" />} 
                        />
                        <Route 
                            path="/artists/:name" 
                            element={<ListingsPage />} 
                        />
                    </Routes>
                )}
            </div>
        </Router>
    );
};

export default App;
