import React, { useState } from 'react';
import './LoginModal.css'; // Import modal styling

const LoginModal = ({ onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoginError(''); // Reset error

        // Prepare the login request
        const loginData = { Email: email, Password: password };

        try {
            const response = await fetch('https://api.gradies.com/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (response.ok) {
                const token = await response.text();
                document.cookie = `authToken=${token}; path=/; secure; samesite=strict; max-age=${60 * 60 * 24}`; // Store token in cookies for 1 day
                localStorage.setItem('brokerEmail', email); // Optionally store email
                onLoginSuccess(); // Call the success callback to proceed
            } else {
                setLoginError('Invalid login credentials. Please try again.');
            }
        } catch (error) {
            setLoginError('Error logging in. Please check your connection and try again.');
        }
    };

    return (
        <div className="login-modal-overlay">
            <div className="login-modal">
                <h2>Login Required</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Email:</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required
                        />
                    </div>
                    {loginError && <p className="error-message">{loginError}</p>}
                    <button type="submit" className="login-btn">Login</button>
                </form>
            </div>
        </div>
    );
};

export default LoginModal;
