import React, { useState, useEffect } from 'react';
import './BrokerContent.css';

const BrokerContent = ({ selectedSection, certNumber }) => {
    const [orders, setOrders] = useState([]);
    const [certs, setCerts] = useState([]);
    const [loading, setLoading] = useState(false); // Update to false to avoid loading on tab load
    const [error, setError] = useState(null);
    const [psaOrderNumber, setPsaOrderNumber] = useState(''); // Track the input value for PSA order number
    const [totalAmount, setTotalAmount] = useState(0); // Track total value of certs

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const handleImageClick = (imageUrl) => {
        setModalImageSrc(imageUrl);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (selectedSection === 'PSA Orders') {
            fetchOrders();
        }
    }, [selectedSection]);

    const fetchOrders = async () => {
        setLoading(true);
        setError(null); // Reset any previous error

        try {
            const email = localStorage.getItem('brokerEmail'); // Get broker email from localStorage
            const response = await fetch(`https://api.gradies.com/orders/psa?brokerEmail=${email}`);
            if (!response.ok) {
                throw new Error(`Failed to load PSA Orders: ${response.statusText}`);
            }
            const result = await response.json();
            setOrders(result.orders || []); // Gracefully handle if orders are not found
        } catch (error) {
            setError(error.message); // Store error message
        } finally {
            setLoading(false);
        }
    };

    const fetchCerts = async () => {
        setLoading(true);
        setError(null); // Reset any previous error

        try {
            const response = await fetch(`https://api.gradies.com/certs?psaOrderNumber=${psaOrderNumber}`);
            if (!response.ok) throw new Error(`Failed to load certs: ${response.statusText}`);
            const result = await response.json();
            setCerts(result.certs || []); // Gracefully handle if certs are not found
            setTotalAmount(result.total_amount || 0); // Set total amount from the API
        } catch (error) {
            setError(error.message); // Store error message
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (psaOrderNumber.trim()) {
            fetchCerts(); // Fetch certs when the form is submitted
        } else {
            setError("Please enter a valid PSA Order Number.");
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="broker-content">
            <h2>{selectedSection}</h2>

            {/* Display Total Value in the top right */}
            {selectedSection === 'Certs' && (
                <div className="total-value">
                    Total Value: ${totalAmount.toFixed(2)}
                </div>
            )}

            {/* PSA Orders Section */}
            {selectedSection === 'PSA Orders' && (
                <>
                    {error && <p className="error-message">{error}</p>} {/* Show error message if it exists */}
                    {orders.length > 0 ? (
                        <table className="psa-orders-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Buyer Email</th>
                                    <th>Seller Email</th>
                                    <th>Cert Number</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order) => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.buyer_email}</td>
                                        <td>{order.seller_email}</td>
                                        <td>{order.cert_number}</td>
                                        <td>{order.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No PSA Orders available at this time.</p> // Gracefully show a message if no orders are present
                    )}
                </>
            )}

            {/* Certs Section with Input */}
            {selectedSection === 'Certs' && (
                <div>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="psaOrderNumber">Enter PSA Order Number:</label>
                        <input
                            type="text"
                            id="psaOrderNumber"
                            value={psaOrderNumber}
                            onChange={(e) => setPsaOrderNumber(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>

                    {loading && <p>Loading certs...</p>}
                    {error && <p className="error-message">{error}</p>}

                    {certs.length > 0 && (
                        <table className="certs-table">
                            <thead>
                                <tr>
                                    <th>Image</th> {/* New column for cert image */}
                                    <th>Cert Number</th>
                                    <th>Name</th>
                                    <th>Set #</th>
                                    <th>PSA Set</th>
                                    <th>Card Set Name</th>
                                    <th>Grade</th>
                                    <th>PriceCharting Price</th>
                                    <th>PriceCharting Product</th>
                                    <th>Total Population</th>
                                </tr>
                            </thead>
                            <tbody>
                                {certs.map((cert) => (
                                    <tr key={cert.cert_number}>
                                        <td>
                                            {cert.cert_images?.front_image_url && (
                                                <img
                                                    src={cert.cert_images.front_image_url}
                                                    alt="Cert Front"
                                                    className="cert-image"
                                                    onClick={() => handleImageClick(cert.cert_images.front_image_url)}

                                                />
                                            )}
                                        </td>
                                        <td>{cert.cert_number}</td>
                                        <td>{cert.name}</td>
                                        <td>#{cert.set_number}</td>
                                        <td>{cert.set}</td>
                                        <td>{cert.card?.set?.name}</td>
                                        <td>{cert.grade}</td>
                                        <td>{cert.price_charting_price ? `$${cert.price_charting_price}` : ''}</td>
                                        <td>{cert.pricing_data?.product_name}</td>
                                        <td>{cert.total_population}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {certs.length === 0 && !loading && <p>No certs available for this PSA order number.</p>}
                </div>
            )}
            {/* Modal for displaying large image */}
            {isModalOpen && (
                <div className="image-modal-overlay show-modal" onClick={handleCloseModal}>
                    <img src={modalImageSrc} alt="Large Cert" className="image-modal-content" />
                </div>
            )}
        </div>
    );
};

export default BrokerContent;
